/* From Uiverse.io by Cybercom682 */
.number-control {
  display: flex;
  align-items: center;
}

.number-left::before,
.number-right::after {
  content: attr(data-content);
  background-color: #1AA5C0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  width: 20px;
  color: white;
  transition: background-color 0.3s;
  cursor: pointer;
  height: 2.2rem;
  border-radius: 0.25rem;
}

.number-left::before {
  content: "-";
}

.number-right::after {
  content: "+";
}

.number-quantity {
  padding: 0.25rem;
  padding-left: 20px;
  border: 0;
  width: 50px;
  -moz-appearance: textfield;
  border-top: 1px solid #8E8E8E;
  border-bottom: 1px solid #8E8E8E;
}

.number-left:hover::before,
.number-right:hover::after {
  background-color: #666666;
}

.error-message {
  position: absolute;
  width: 22rem;
  /* top: -30px; Adjust this value as needed */
  top: 30;
  left: 35;
  right: 0;
  margin-right: 17rem;
  margin-top:4rem;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeIn 0.5s;
}